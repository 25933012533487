import { WebStorageStateStore } from "oidc-client-ts";

const awsConfig = {
    aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_USERPOOL_CLIENT_ID,
    api_endpoint: process.env.REACT_APP_USERPOOL_API_ENDPOINT,
    oauth_authority: process.env.REACT_APP_OAUTH_AUTHORITY,
    oauth_redirect_url: process.env.REACT_APP_OAUTH_REDIRECT_URL,
    oauth_cognito_domain: process.env.REACT_APP_OAUTH_COGNITO_DOMAIN,
};


const cognitoAuthConfig = {
    authority: awsConfig.oauth_authority,
    client_id: awsConfig.aws_user_pools_web_client_id,
    redirect_uri: awsConfig.oauth_redirect_url, // Use the conditionally set redirectUri variable
    response_type: "code",
    scope: "aws.cognito.signin.user.admin email openid phone profile",
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    cognito_domain: awsConfig.oauth_cognito_domain,
  };

export { cognitoAuthConfig, awsConfig };