import { useDispatch, useSelector } from "react-redux";
import Home from "./User/Home";
import LoginPage from "./User/login";
import { Alert, Box, IconButton, Snackbar } from "@mui/material";
import SpotifyWidget from "../Components/Playlist/SpotifyWidget";
import { clearError } from "../Store/actions/errorActions";
import { Close } from "@mui/icons-material";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { useEffect, useState } from "react";
import { refreshCurrentSession } from "../Store/actions/userActions";
import { setCookie } from "../Utils/InviteeUtil";

const MainComponent = () => {
  const { isError, errorMessage } = useSelector((state) => state.errorReducer);
  const { showPopups } = useSelector((state) => state.userReducer);
  const auth = useAuth();
  const dispatch = useDispatch();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const clearQueryString = () => {
      const url = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.replaceState({ path: url }, '', url); // Use replaceState to avoid adding a new entry
    };

    const params = new URLSearchParams(window.location.search);
    if (params.get("quicknav") === "logout") {
      clearQueryString();
    }
    if (params.get("quicknav") === "photos") {
      setCookie("tab_index", 50, 1);
    }
    const isPostLogout = params.get("post_logout_success") === 'true';
    console.log(auth.isAuthenticated, auth.user)
    if (auth.isAuthenticated
      && auth.user?.id_token
      && auth.user?.profile["custom:userId"]
    ) {
      dispatch(refreshCurrentSession(auth.user.id_token, auth.user.profile["custom:userId"], true));
    }

    if (!hasAuthParams() && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect();
    } else if (auth.isAuthenticated) {
      setIsAuthenticated(true);
      clearQueryString(); // Clear the query string only after successful authentication
    }

  }, [dispatch, auth]);

  const handleClose = () => { dispatch(clearError()); };

  return (
    <>
      <Box overflow="hidden">
        <Snackbar
          open={isError}
          autoHideDuration={5000}
          onClose={handleClose}
          color="red"
          action={<IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}>
            <Close />
          </IconButton>}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {errorMessage}</Alert>
        </Snackbar>
        {isAuthenticated ? <Home auth={auth} /> : <LoginPage />}
      </Box>
      {showPopups && <SpotifyWidget />}
      <p>V3.2 © 2025 Marco Benvenuti - All rights reserved.</p>
    </>
  );
};

export default MainComponent;
