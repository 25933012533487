import React from "react";
import { AccordionSummary, IconButton, Tooltip } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import { Box } from "@mui/system";

const CustomAccordionSummary = ({ onUpload, onConfirmUpload, children }) => {
  return (
    <AccordionSummary
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}>
      <Box sx={{ width: "20%", textAlign: 'left', m: 1 }} >
        <h2>Share</h2>
      </Box>
      <Box sx={{ width: "80%", textAlign: 'right', m: 1 }}>
        <Tooltip title="Choose Files">
          <IconButton onClick={onUpload}>
            <FileUploadIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Upload">
          <IconButton onClick={onConfirmUpload}>
            <CloudUploadIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Clear All Pending Uploads">
          <IconButton onClick={() => { }}>
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {children}
    </AccordionSummary >
  );
};

export default CustomAccordionSummary;
